.home {
	position: relative;
	background-color: var(--background-color);
	overflow: hidden;
	scroll-behavior: smooth;
}
.home__container {
	height: calc(100vh - var(--header-height));
	row-gap: 5rem;
}
.home__title {
	align-self: flex-end;
	font-size: var(--big-font-size);
	color: var(--first-color);
	line-height: 0.8;
	z-index: 1;
}
.home__title span {
	text-shadow: 5px 10px 15px rgba(255, 255, 255, 0.4);
}
.home__scroll {
	align-self: flex-end;
	padding-bottom: var(--mb-4);
}
.home__scroll-link {
	writing-mode: vertical-lr;
	transform: rotate(-180deg);
	color: var(--white-color);
}
.home__img {
	position: absolute;
	right: -5%;
	bottom: -5%;
	width: 250px;
}

/* MEDIA QUERIES */
@media screen and (min-width: 768px) {
	.home__container {
		height: 100vh;
		grid-template-rows: 1.7fr 1fr;
		row-gap: 0;
	}
	.home__img {
		width: 524px;
		right: 10%;
	}
	.home__scroll {
		padding-bottom: 50px;
	}
}
