.about {
	scroll-behavior: smooth;
}

.about__container {
	justify-items: center;
	row-gap: 2rem;
	text-align: center;
}
.about__img {
	display: flex;
	justify-content: center;
	padding-top: 1rem;
	width: 120px;
	height: 120px;
	background-color: var(--background-color);
	border-radius: 50%;
	overflow: hidden;
}
.about__img img {
	position: relative;
	bottom: 16px;
	height: 120px;
}
.about__subtitle {
	font-size: var(--h2-font-size);
	color: var(--first-color);
	margin-bottom: var(--mb-1);
}
.about__text {
	margin-bottom: var(--mb-4);
}
.about__profession {
	display: block;
	margin-bottom: var(--mb-4);
}
.about__social-icon box-icon {
	font-size: 1.4rem;
	margin: 0 var(--mb-1);
}
.about__social-icon:hover {
	color: var(--first-color);
}

/* MEDIA QUERIES */
@media screen and (min-width: 768px) {
	.about__container {
		grid-template-columns: repeat(2, 1fr);
		align-items: center;
		text-align: initial;
		padding: 4rem 0;
	}
	.about__img {
		width: 200px;
		height: 200px;
	}
	.about__img img {
		height: 200px;
	}
}
