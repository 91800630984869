@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap);
/* GOOGLE FONTS */

/* VARIABLES*/
:root {
	--header-height: 3rem;
	--font-medium: 500;
}

/* COLORS */
:root {
	--background-color: #000;
	--first-color: #5361ff;
	--white-color: #fafaff;
	--dark-color: #2a3b47;
	--text-color: #697477;
}

/* FONTS */
:root {
	--body-font: "Montserrat", sans-serif;
	--big-font-size: 6.25rem;
	--h2-font-size: 1.25rem;
	--normal-font-size: 0.938rem;
	--small-font-size: 0.813rem;
}
@media screen and (min-width: 768px) {
	:root {
		--big-font-size: 10.5rem;
		--h2-font-size: 2rem;
		--normal-font-size: 1rem;
		--small-font-size: 0.875rem;
	}
}

/* MARGINS */
:root {
	--mb-1: 0.5rem;
	--mb-2: 1rem;
	--mb-3: 1.5rem;
	--mb-4: 2rem;
}

/* Z-INDEX */
:root {
	--z-fixed: 100;
}

/* BASE */
*,
::before,
::after {
	box-sizing: border-box;
}
html {
	scroll-behavior: smooth;
}
body {
	margin: 3rem 0 0 0;
	margin: var(--header-height) 0 0 0;
	font-family: "Montserrat", sans-serif;
	font-family: var(--body-font);
	font-size: 0.938rem;
	font-size: var(--normal-font-size);
	font-weight: 500;
	font-weight: var(--font-medium);
	color: #697477;
	color: var(--text-color);
	line-height: 1.6rem;
}
body::-webkit-scrollbar {
	width: 1rem;
}
body::-webkit-scrollbar-track {
	background-color: #1e1e24;
}
body::-webkit-scrollbar-thumb {
	/* background-color: var(--first-color);
	 */
	background-image: linear-gradient(
		180deg,
		#708ad4 0%,
		#5361ff 50%
	);
	background-image: linear-gradient(
		180deg,
		#708ad4 0%,
		var(--first-color) 50%
	);
	box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
	border-radius: 10px;
}
h1,
h2,
p {
	margin: 0;
}
ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
a {
	text-decoration: none;
	color: #697477;
	color: var(--text-color);
}
img {
	max-width: 100%;
	height: auto;
	display: block;
}

/* CSS CLASS */
.section {
	padding: 3rem 0;
}
.section-title {
	position: relative;
	font-size: 1.25rem;
	font-size: var(--h2-font-size);
	color: #2a3b47;
	color: var(--dark-color);
	margin: 2rem 0;
	margin: var(--mb-4) 0;
	text-align: center;
}
.section-title::after {
	position: absolute;
	content: "";
	width: 32px;
	height: 0.18rem;
	top: 2rem;
	left: 0;
	right: 0;
	margin: auto;
	background-color: #5361ff;
	background-color: var(--first-color);
}

/* LAYOUT */
.bd-grid {
	max-width: 1024px;
	display: grid;
	grid-template-columns: 100%;
	grid-column-gap: 2rem;
	width: calc(100% - 2rem);
	margin-left: 1rem;
	margin-left: var(--mb-2);
	margin-right: 1rem;
	margin-right: var(--mb-2);
}

.l-header {
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	z-index: var(--z-fixed);
	background-color: #000;
	background-color: var(--background-color);
}

/* MEDIA QUERIES */
@media screen and (min-width: 768px) {
	.app {
		margin: 0;
	}
	.section {
		padding-top: 4rem;
	}
	.section-title {
		margin-bottom: 3rem;
	}
	.section-title::after {
		width: 64px;
		top: 3rem;
	}
}

@media screen and (min-width: 1024px) {
	.bd-grid {
		margin-left: auto;
		margin-right: auto;
	}
}

.fiverr {
	background-color: rgb(34 197 94);
	border-radius: 6px;
	border: none;
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	font-weight: bold;
	line-height: 36px;
	transition-duration: 300ms;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	padding: 10px 8px;
	position: fixed;
	right: 20px;
	bottom: 20px;
	z-index: 10;
	text-align: center;
	-webkit-animation: bounce 1s infinite;
	        animation: bounce 1s infinite;
}
.fiverr:focus {
	outline: none;
}

.fiverr a {
	color: var(--white-color);
	text-decoration: none;
}

@media (min-width: 768px) {
	.fiverr {
		font-size: 30px;
		padding: 14px 10px;
	}
	.fiverr:hover {
		-webkit-transform: scale(1.1);
		        transform: scale(1.1);
	}
}

@-webkit-keyframes bounce {
	0%,
	100% {
		-webkit-transform: translateY(-25%);
		        transform: translateY(-25%);
		-webkit-animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
		        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
	}
	50% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
		-webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
		        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
	}
}

@keyframes bounce {
	0%,
	100% {
		-webkit-transform: translateY(-25%);
		        transform: translateY(-25%);
		-webkit-animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
		        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
	}
	50% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
		-webkit-animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
		        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
	}
}

/* NAV */
.l-header {
	scroll-behavior: smooth;
}
.nav {
	height: var(--header-height);
	display: flex;
	justify-content: space-between;
	align-items: center;
}

/* MEDIA QUERIES */
@media screen and (max-width: 768px) {
	.nav__menu {
		position: fixed;
		top: var(--header-height);
		right: -100%;
		width: 100%;
		height: 100%;
		padding: 4rem;
		text-align: center;
		background-color: rgba(0, 0, 0, 0.7);
		transition: 0.5s;
		-webkit-backdrop-filter: blur(10px);
		        backdrop-filter: blur(10px);
	}
}

.nav__item {
	margin-bottom: var(--mb-4);
}
.nav__link {
	position: relative;
	color: var(--white-color);
}
.nav__link:hover {
	color: var(--first-color);
}
.nav__logo {
	color: var(--white-color);
}
.nav__toggle {
	color: var(--white-color);
	font-size: 1.5rem;
	cursor: pointer;
}

/* SHOW MENU */
.show__menu {
	right: 0;
}

/* ACTIVE MENU */
.active::after {
	position: absolute;
	content: "";
	width: 100%;
	height: 0.18rem;
	left: 0;
	top: 2rem;
	background-color: var(--first-color);
}

/* MEDIA QUERIES */
@media screen and (min-width: 768px) {
	.nav {
		height: calc(var(--header-height) + 1rem);
	}
	.nav__list {
		display: flex;
	}
	.nav__item {
		margin-left: var(--mb-4);
		margin-bottom: 0;
	}
	.nav__toggle {
		display: none;
	}
	.nav__link {
		color: var(--white-color);
	}
	nav__link:hover {
		color: var(--first-color);
	}
	.active::after {
		background-color: var(--white-color);
	}
}

.home {
	position: relative;
	background-color: var(--background-color);
	overflow: hidden;
	scroll-behavior: smooth;
}
.home__container {
	height: calc(100vh - var(--header-height));
	grid-row-gap: 5rem;
	row-gap: 5rem;
}
.home__title {
	align-self: flex-end;
	font-size: var(--big-font-size);
	color: var(--first-color);
	line-height: 0.8;
	z-index: 1;
}
.home__title span {
	text-shadow: 5px 10px 15px rgba(255, 255, 255, 0.4);
}
.home__scroll {
	align-self: flex-end;
	padding-bottom: var(--mb-4);
}
.home__scroll-link {
	-webkit-writing-mode: vertical-lr;
	    -ms-writing-mode: tb-lr;
	        writing-mode: vertical-lr;
	-webkit-transform: rotate(-180deg);
	        transform: rotate(-180deg);
	color: var(--white-color);
}
.home__img {
	position: absolute;
	right: -5%;
	bottom: -5%;
	width: 250px;
}

/* MEDIA QUERIES */
@media screen and (min-width: 768px) {
	.home__container {
		height: 100vh;
		grid-template-rows: 1.7fr 1fr;
		grid-row-gap: 0;
		row-gap: 0;
	}
	.home__img {
		width: 524px;
		right: 10%;
	}
	.home__scroll {
		padding-bottom: 50px;
	}
}

.about {
	scroll-behavior: smooth;
}

.about__container {
	justify-items: center;
	grid-row-gap: 2rem;
	row-gap: 2rem;
	text-align: center;
}
.about__img {
	display: flex;
	justify-content: center;
	padding-top: 1rem;
	width: 120px;
	height: 120px;
	background-color: var(--background-color);
	border-radius: 50%;
	overflow: hidden;
}
.about__img img {
	position: relative;
	bottom: 16px;
	height: 120px;
}
.about__subtitle {
	font-size: var(--h2-font-size);
	color: var(--first-color);
	margin-bottom: var(--mb-1);
}
.about__text {
	margin-bottom: var(--mb-4);
}
.about__profession {
	display: block;
	margin-bottom: var(--mb-4);
}
.about__social-icon box-icon {
	font-size: 1.4rem;
	margin: 0 var(--mb-1);
}
.about__social-icon:hover {
	color: var(--first-color);
}

/* MEDIA QUERIES */
@media screen and (min-width: 768px) {
	.about__container {
		grid-template-columns: repeat(2, 1fr);
		align-items: center;
		text-align: left;
		text-align: initial;
		padding: 4rem 0;
	}
	.about__img {
		width: 200px;
		height: 200px;
	}
	.about__img img {
		height: 200px;
	}
}

.education {
	background-color: var(--white-color);
	scroll-behavior: smooth;
}

.education__container {
	align-items: center;
	justify-items: center;
	grid-row-gap: 2rem;
	row-gap: 2rem;
	text-align: center;
}

.education__img img {
	width: 80%;
	margin: 0 auto;
	position: relative;
	bottom: 0;
}

.education__text {
	margin-bottom: var(--mb-4);
}

/* MEDIA QUERIES */
@media screen and (min-width: 768px) {
	.education__container {
		grid-template-columns: repeat(2, 1fr);
		align-items: center;
		text-align: left;
		text-align: initial;
		padding: 4rem 0;
	}

	.education__img img {
		width: 60%;
		margin-left: 30%;
	}
}

.skills {
	scroll-behavior: smooth;
}

.skills__container {
	align-items: center;
	grid-row-gap: 2rem;
	row-gap: 2rem;
}

.skills__box {
	margin-left: 0;
}

.skills__subtitle {
	color: var(--first-color);
	margin-bottom: var(--mb-1);
}
.skills__name {
	display: inline-block;
	font-size: var(--small-font-size);
	margin-right: var(--mb-2);
	margin-bottom: var(--mb-1);
	padding: 0.25rem 0.5rem;
	background-color: var(--first-color);
	color: var(--white-color);
	border-radius: 0.25rem;
}

.html {
	background-color: rgb(211, 66, 66);
}
.css {
	background-color: rgb(24, 81, 187);
}
.sass {
	background-color: #ce649a;
	color: whitesmoke;
}
.bootstrap {
	background-color: #8712fa;
	color: whitesmoke;
}
.bulma {
	background-color: #03d1b2;
	color: black;
}
.tailwindcss {
	background-color: #37bcf8;
	color: black;
}
.js {
	background-color: #f0d91d;
	color: black;
}
.ts {
	background-color: #2f75c1;
	color: white;
}
.react {
	background-color: rgb(75, 74, 73);
	color: aqua;
}
.next {
	background-color: black;
	color: whitesmoke;
}
.graphql {
	background-color: #171e26;
	color: #df0298;
}
.rest {
	background-color: #171e26;
	color: whitesmoke;
}
.docker {
	background-color: #2592e5;
	color: #f7f7f7;
}
.git {
	background-color: #e84d32;
	color: whitesmoke;
}
.github {
	background-color: #333;
	color: whitesmoke;
}
.gitlab {
	background-color: #e24328;
	color: #222;
}
.figma {
	background-color: #0cc97f;
	color: #333;
}
.adobexd {
	background-color: #440235;
	color: #f85fed;
}

.skills__img img {
	width: 80%;
	margin: 0 auto;
	position: relative;
	bottom: 0;
}

/* MEDIA QUERIES */
@media screen and (min-width: 768px) {
	.skills__container {
		grid-template-columns: repeat(2, 1fr);
	}
	.skills__box {
		margin-left: 10%;
	}
}

.portfolio {
	background-color: var(--white-color);
	scroll-behavior: smooth;
}
.portfolio__container {
	justify-items: center;
	grid-row-gap: 2rem;
	row-gap: 2rem;
}
.portfolio__img {
	position: relative;
	overflow: hidden;
}
.portfolio__img img {
	border-radius: 0.5rem;
	height: 100%;
	width: 100%;
}
.portfolio__link {
	position: absolute;
	bottom: -100%;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.7);
	border-radius: 0.5rem;
	cursor: pointer;
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
	transition: 0.3s;
}
.portfolio__img:hover .portfolio__link {
	bottom: 0;
}
.portfolio__link-name {
	/* display: inline-block; */
	font-size: var(--small-font-size);
	/* margin-right: var(--mb-2); */
	margin-bottom: var(--mb-3);
	padding: 0.25rem 0.5rem;
	background-color: var(--first-color);
	color: var(--white-color);
	border-radius: 0.25rem;
}
.live {
	background-color: rgb(253, 183, 52);
	color: rgb(44, 44, 44);
}
.github {
	background-color: rgb(43, 42, 41);
	color: rgb(226, 226, 226);
}

/* MEDIA QUERIES */
@media screen and (min-width: 768px) {
	.portfolio__container {
		width: 50%;
		margin: 0 auto;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 1fr);
		grid-column-gap: 2rem;
		-webkit-column-gap: 2rem;
		        column-gap: 2rem;
	}
}

.contact {
	scroll-behavior: smooth;
}

.contact__container {
	grid-row-gap: 2rem;
	row-gap: 2rem;
}
.contact__subtitle {
	font-size: var(--normal-font-size);
	color: var(--first-color);
}
.contact__text {
	display: inline-block;
	margin-bottom: var(--mb-2);
}
.contact__inputs {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 1rem;
	-webkit-column-gap: 1rem;
	        column-gap: 1rem;
}
.contact__input {
	width: 100%;
	padding: 0.8rem;
	outline: none;
	border: 2px solid var(--dark-color);
	font-size: var(--normal-font-size);
	margin-bottom: var(--mb-4);
	border-radius: 0.5rem;
}
.contact__button {
	display: block;
	background-color: var(--first-color);
	color: var(--white-color);
	padding: 0.75rem 2.5rem;
	margin-left: auto;
	border-radius: 0.5rem;
	border: none;
	outline: none;
	font-size: var(--normal-font-size);
	cursor: pointer;
}

/* MEDIA QUERIES */
@media screen and (min-width: 768px) {
	.contact__container {
		grid-template-columns: repeat(2, 1fr);
		justify-items: center;
	}
	.contact__form {
		width: 380px;
	}
}

.footer {
	background-color: var(--background-color);
	scroll-behavior: smooth;
}
.footer__container {
	grid-row-gap: 2rem;
	row-gap: 2rem;
}

.footer__title {
	font-size: var(--normal-font-size);
	color: var(--white-color);
	margin: var(--mb-2) auto;
}
.footer__data ul {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
}
.footer__link {
	padding: 0.25rem 0;
}
.footer__link:hover {
	color: var(--first-color);
}
.footer__social {
	margin-right: var(--mb-1);
}
.footer__social:hover {
	color: var(--first-color);
}

/* MEDIA QUERIES */
@media screen and (min-width: 768px) {
	.footer__container {
		grid-template-columns: repeat(3, 1fr);
		justify-items: center;
		align-items: center;
		text-align: center;
	}
}

