/* GOOGLE FONTS */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap");

/* VARIABLES*/
:root {
	--header-height: 3rem;
	--font-medium: 500;
}

/* COLORS */
:root {
	--background-color: #000;
	--first-color: #5361ff;
	--white-color: #fafaff;
	--dark-color: #2a3b47;
	--text-color: #697477;
}

/* FONTS */
:root {
	--body-font: "Montserrat", sans-serif;
	--big-font-size: 6.25rem;
	--h2-font-size: 1.25rem;
	--normal-font-size: 0.938rem;
	--small-font-size: 0.813rem;
}
@media screen and (min-width: 768px) {
	:root {
		--big-font-size: 10.5rem;
		--h2-font-size: 2rem;
		--normal-font-size: 1rem;
		--small-font-size: 0.875rem;
	}
}

/* MARGINS */
:root {
	--mb-1: 0.5rem;
	--mb-2: 1rem;
	--mb-3: 1.5rem;
	--mb-4: 2rem;
}

/* Z-INDEX */
:root {
	--z-fixed: 100;
}

/* BASE */
*,
::before,
::after {
	box-sizing: border-box;
}
html {
	scroll-behavior: smooth;
}
body {
	margin: var(--header-height) 0 0 0;
	font-family: var(--body-font);
	font-size: var(--normal-font-size);
	font-weight: var(--font-medium);
	color: var(--text-color);
	line-height: 1.6rem;
}
body::-webkit-scrollbar {
	width: 1rem;
}
body::-webkit-scrollbar-track {
	background-color: #1e1e24;
}
body::-webkit-scrollbar-thumb {
	/* background-color: var(--first-color);
	 */
	background-image: linear-gradient(
		180deg,
		#708ad4 0%,
		var(--first-color) 50%
	);
	box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
	border-radius: 10px;
}
h1,
h2,
p {
	margin: 0;
}
ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
a {
	text-decoration: none;
	color: var(--text-color);
}
img {
	max-width: 100%;
	height: auto;
	display: block;
}

/* CSS CLASS */
.section {
	padding: 3rem 0;
}
.section-title {
	position: relative;
	font-size: var(--h2-font-size);
	color: var(--dark-color);
	margin: var(--mb-4) 0;
	text-align: center;
}
.section-title::after {
	position: absolute;
	content: "";
	width: 32px;
	height: 0.18rem;
	top: 2rem;
	left: 0;
	right: 0;
	margin: auto;
	background-color: var(--first-color);
}

/* LAYOUT */
.bd-grid {
	max-width: 1024px;
	display: grid;
	grid-template-columns: 100%;
	grid-column-gap: 2rem;
	width: calc(100% - 2rem);
	margin-left: var(--mb-2);
	margin-right: var(--mb-2);
}

.l-header {
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: var(--z-fixed);
	background-color: var(--background-color);
}

/* MEDIA QUERIES */
@media screen and (min-width: 768px) {
	.app {
		margin: 0;
	}
	.section {
		padding-top: 4rem;
	}
	.section-title {
		margin-bottom: 3rem;
	}
	.section-title::after {
		width: 64px;
		top: 3rem;
	}
}

@media screen and (min-width: 1024px) {
	.bd-grid {
		margin-left: auto;
		margin-right: auto;
	}
}
