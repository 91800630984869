.footer {
	background-color: var(--background-color);
	scroll-behavior: smooth;
}
.footer__container {
	row-gap: 2rem;
}

.footer__title {
	font-size: var(--normal-font-size);
	color: var(--white-color);
	margin: var(--mb-2) auto;
}
.footer__data ul {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
}
.footer__link {
	padding: 0.25rem 0;
}
.footer__link:hover {
	color: var(--first-color);
}
.footer__social {
	margin-right: var(--mb-1);
}
.footer__social:hover {
	color: var(--first-color);
}

/* MEDIA QUERIES */
@media screen and (min-width: 768px) {
	.footer__container {
		grid-template-columns: repeat(3, 1fr);
		justify-items: center;
		align-items: center;
		text-align: center;
	}
}
