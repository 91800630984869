/* NAV */
.l-header {
	scroll-behavior: smooth;
}
.nav {
	height: var(--header-height);
	display: flex;
	justify-content: space-between;
	align-items: center;
}

/* MEDIA QUERIES */
@media screen and (max-width: 768px) {
	.nav__menu {
		position: fixed;
		top: var(--header-height);
		right: -100%;
		width: 100%;
		height: 100%;
		padding: 4rem;
		text-align: center;
		background-color: rgba(0, 0, 0, 0.7);
		transition: 0.5s;
		backdrop-filter: blur(10px);
	}
}

.nav__item {
	margin-bottom: var(--mb-4);
}
.nav__link {
	position: relative;
	color: var(--white-color);
}
.nav__link:hover {
	color: var(--first-color);
}
.nav__logo {
	color: var(--white-color);
}
.nav__toggle {
	color: var(--white-color);
	font-size: 1.5rem;
	cursor: pointer;
}

/* SHOW MENU */
.show__menu {
	right: 0;
}

/* ACTIVE MENU */
.active::after {
	position: absolute;
	content: "";
	width: 100%;
	height: 0.18rem;
	left: 0;
	top: 2rem;
	background-color: var(--first-color);
}

/* MEDIA QUERIES */
@media screen and (min-width: 768px) {
	.nav {
		height: calc(var(--header-height) + 1rem);
	}
	.nav__list {
		display: flex;
	}
	.nav__item {
		margin-left: var(--mb-4);
		margin-bottom: 0;
	}
	.nav__toggle {
		display: none;
	}
	.nav__link {
		color: var(--white-color);
	}
	nav__link:hover {
		color: var(--first-color);
	}
	.active::after {
		background-color: var(--white-color);
	}
}
