.fiverr {
	background-color: rgb(34 197 94);
	border-radius: 6px;
	border: none;
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	font-weight: bold;
	line-height: 36px;
	transition-duration: 300ms;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	padding: 10px 8px;
	position: fixed;
	right: 20px;
	bottom: 20px;
	z-index: 10;
	text-align: center;
	animation: bounce 1s infinite;
}
.fiverr:focus {
	outline: none;
}

.fiverr a {
	color: var(--white-color);
	text-decoration: none;
}

@media (min-width: 768px) {
	.fiverr {
		font-size: 30px;
		padding: 14px 10px;
	}
	.fiverr:hover {
		transform: scale(1.1);
	}
}

@keyframes bounce {
	0%,
	100% {
		transform: translateY(-25%);
		animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
	}
	50% {
		transform: translateY(0);
		animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
	}
}
