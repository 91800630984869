.education {
	background-color: var(--white-color);
	scroll-behavior: smooth;
}

.education__container {
	align-items: center;
	justify-items: center;
	row-gap: 2rem;
	text-align: center;
}

.education__img img {
	width: 80%;
	margin: 0 auto;
	position: relative;
	bottom: 0;
}

.education__text {
	margin-bottom: var(--mb-4);
}

/* MEDIA QUERIES */
@media screen and (min-width: 768px) {
	.education__container {
		grid-template-columns: repeat(2, 1fr);
		align-items: center;
		text-align: initial;
		padding: 4rem 0;
	}

	.education__img img {
		width: 60%;
		margin-left: 30%;
	}
}
