.contact {
	scroll-behavior: smooth;
}

.contact__container {
	row-gap: 2rem;
}
.contact__subtitle {
	font-size: var(--normal-font-size);
	color: var(--first-color);
}
.contact__text {
	display: inline-block;
	margin-bottom: var(--mb-2);
}
.contact__inputs {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 1rem;
}
.contact__input {
	width: 100%;
	padding: 0.8rem;
	outline: none;
	border: 2px solid var(--dark-color);
	font-size: var(--normal-font-size);
	margin-bottom: var(--mb-4);
	border-radius: 0.5rem;
}
.contact__button {
	display: block;
	background-color: var(--first-color);
	color: var(--white-color);
	padding: 0.75rem 2.5rem;
	margin-left: auto;
	border-radius: 0.5rem;
	border: none;
	outline: none;
	font-size: var(--normal-font-size);
	cursor: pointer;
}

/* MEDIA QUERIES */
@media screen and (min-width: 768px) {
	.contact__container {
		grid-template-columns: repeat(2, 1fr);
		justify-items: center;
	}
	.contact__form {
		width: 380px;
	}
}
