.portfolio {
	background-color: var(--white-color);
	scroll-behavior: smooth;
}
.portfolio__container {
	justify-items: center;
	row-gap: 2rem;
}
.portfolio__img {
	position: relative;
	overflow: hidden;
}
.portfolio__img img {
	border-radius: 0.5rem;
	height: 100%;
	width: 100%;
}
.portfolio__link {
	position: absolute;
	bottom: -100%;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: rgba(255, 255, 255, 0.7);
	border-radius: 0.5rem;
	cursor: pointer;
	backdrop-filter: blur(10px);
	-webkit-backdrop-filter: blur(10px);
	transition: 0.3s;
}
.portfolio__img:hover .portfolio__link {
	bottom: 0;
}
.portfolio__link-name {
	/* display: inline-block; */
	font-size: var(--small-font-size);
	/* margin-right: var(--mb-2); */
	margin-bottom: var(--mb-3);
	padding: 0.25rem 0.5rem;
	background-color: var(--first-color);
	color: var(--white-color);
	border-radius: 0.25rem;
}
.live {
	background-color: rgb(253, 183, 52);
	color: rgb(44, 44, 44);
}
.github {
	background-color: rgb(43, 42, 41);
	color: rgb(226, 226, 226);
}

/* MEDIA QUERIES */
@media screen and (min-width: 768px) {
	.portfolio__container {
		width: 50%;
		margin: 0 auto;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 1fr);
		column-gap: 2rem;
	}
}
