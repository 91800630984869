.skills {
	scroll-behavior: smooth;
}

.skills__container {
	align-items: center;
	row-gap: 2rem;
}

.skills__box {
	margin-left: 0;
}

.skills__subtitle {
	color: var(--first-color);
	margin-bottom: var(--mb-1);
}
.skills__name {
	display: inline-block;
	font-size: var(--small-font-size);
	margin-right: var(--mb-2);
	margin-bottom: var(--mb-1);
	padding: 0.25rem 0.5rem;
	background-color: var(--first-color);
	color: var(--white-color);
	border-radius: 0.25rem;
}

.html {
	background-color: rgb(211, 66, 66);
}
.css {
	background-color: rgb(24, 81, 187);
}
.sass {
	background-color: #ce649a;
	color: whitesmoke;
}
.bootstrap {
	background-color: #8712fa;
	color: whitesmoke;
}
.bulma {
	background-color: #03d1b2;
	color: black;
}
.tailwindcss {
	background-color: #37bcf8;
	color: black;
}
.js {
	background-color: #f0d91d;
	color: black;
}
.ts {
	background-color: #2f75c1;
	color: white;
}
.react {
	background-color: rgb(75, 74, 73);
	color: aqua;
}
.next {
	background-color: black;
	color: whitesmoke;
}
.graphql {
	background-color: #171e26;
	color: #df0298;
}
.rest {
	background-color: #171e26;
	color: whitesmoke;
}
.docker {
	background-color: #2592e5;
	color: #f7f7f7;
}
.git {
	background-color: #e84d32;
	color: whitesmoke;
}
.github {
	background-color: #333;
	color: whitesmoke;
}
.gitlab {
	background-color: #e24328;
	color: #222;
}
.figma {
	background-color: #0cc97f;
	color: #333;
}
.adobexd {
	background-color: #440235;
	color: #f85fed;
}

.skills__img img {
	width: 80%;
	margin: 0 auto;
	position: relative;
	bottom: 0;
}

/* MEDIA QUERIES */
@media screen and (min-width: 768px) {
	.skills__container {
		grid-template-columns: repeat(2, 1fr);
	}
	.skills__box {
		margin-left: 10%;
	}
}
